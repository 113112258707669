<template>
  <div>

    <b-modal
      id="tradeModal"
      v-model="VerifyCouponModal"
      :title="$t('equity_bond.titles.verify_trade')"
      @hidden="resetTradeModal"
      size="xl"
      :ok-title="$t('equity_bond.modals.verify_trade_modal_ok')"
      :cancel-title="$t('equity_bond.modals.modal_cancel')"
      @ok="tradeOkOperation"
      :body-bg-variant="'light'"
      :header-bg-variant="'dark'"
      class="tradeModal"
      body-class="compact_form_modal"
      footer-class ="compact_modal_footer"
      no-close-on-backdrop
      
    >
    <b-overlay :show="tradeModalLoading" rounded="sm" no-fade>
        <b-container>
          <validation-observer ref="tradeValidation">
            <b-form>
              <!-- /*******************************Order Details***************************************************************** */ -->
              <b-row>
                <b-card 
                  :title="$t('equity_bond.titles.order_details')" 
                  >
                  
                  <b-row>
                    <!-- order date-->
                    <b-col cols="12" xl="2" lg="2" md="4" sm="6">
                      <b-form-group 
                        :label="$t('equity_bond.trade_form.value_date')"
                        >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.value_date')"
                          rules="required"
                        >
                          <flat-pickr
                            :disabled="true"
                            ref="orderDate"
                            :placeholder="$t('equity_bond.trade_form.value_date')"
                            v-model="tradeForm.ValueDate"
                            onClose="testClose"
                            class="form-control "
                            :config="orderDateConfig"
                            :state="errors.length > 0 ? false : null"
                            
                            
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col cols="6" xl="1" lg="2" md="4" sm="6">
                      <b-form-group 
                      :label="$t('equity_bond.trade_form.ticker')" 
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.ticker')"
                        >
                    
                          <b-form-input
                          :disabled="true"
                                id="T+"
                                v-model.number="tradeForm.Ticker"
                                name="T+"
                                placeholder="T+"
                                type="text"
                                
                              />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    
                    <b-col cols="6" xl="1" lg="2" md="4" sm="6">
                      <b-form-group 
                      :label="$t('equity_bond.trade_form.cost_center')" 
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.cost_center')"
                        >
                    
                          
                      <v-select
                        v-model="tradeForm.costCenter"
                        :options="optCostCenters"
                        :clearable="false"
                        :placeholder="$t('equity_bond.trade_form.select_cost_center')"
                        :reduce="(val) => val.CostCenter"
                        label = 'CostCenter'
                        

                      />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>



                    <b-col cols="6" xl="1" lg="2" md="4" sm="6">
                      <b-form-group 
                      :label="$t('equity_bond.trade_form.cost_center')" 
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.cost_center')"
                        >
                    
                          
                      <v-select
                        v-model="tradeForm.costCenter"
                        :options="optCostCenters"
                        :clearable="false"
                        :placeholder="$t('equity_bond.trade_form.select_cost_center')"
                        :reduce="(val) => val.CostCenter"
                        label = 'CostCenter'
                        

                      />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col cols="6" xl="1" lg="2" md="4" sm="6">
                      <b-form-group 
                      :label="$t('equity_bond.trade_form.cpty_account')" 
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.cpty_account')"
                          rules="required"

                        >
                    
                          
                      <v-select
                        v-model="tradeForm.counterpartyAccount"
                        :options="optCounterParties"
                        :clearable="false"
                        :placeholder="$t('equity_bond.trade_form.cpty_account')"
                        :reduce="(val) => val.AccountNumber"
                        label = 'AccountName'

                      />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    
                    <b-col cols="6" xl="1" lg="2" md="4" sm="6">
                      <b-form-group 
                      :label="$t('equity_bond.trade_form.account_name')" 
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.account_name')"
                        >
                    
                          <b-form-input
                          :disabled="true"
                                id="T+"
                                v-model.number="tradeForm.AccountName"
                                name="T+"
                                placeholder="T+"
                                type="text"
                                
                              />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col cols="6" xl="1" lg="2" md="4" sm="6">
                      <b-form-group 
                      :label="$t('equity_bond.trade_form.account_code')" 
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.account_code')"
                        >
                    
                          <b-form-input
                          :disabled="true"
                                id="T+"
                                v-model.number="tradeForm.AccountCode"
                                name="T+"
                                placeholder="T+"
                                type="text"
                                
                              />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    
                    <b-col cols="6" xl="1" lg="2" md="4" sm="6">
                      <b-form-group 
                      :label="$t('equity_bond.trade_form.ccy')" 
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.ccy')"
                        >
                    
                          <b-form-input
                          :disabled="true"
                                id="T+"
                                v-model.number="tradeForm.Ccy"
                                name="T+"
                                placeholder="T+"
                                type="text"
                                
                              />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>





                    <b-col cols="6" xl="1" lg="2" md="4" sm="6">
                      <b-form-group 
                      :label="$t('equity_bond.trade_form.quantity')" 
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.quantity')"
                        >
                   
                          <b-form-input
                          :disabled="true"
                                id="T+"
                                v-model.number="tradeForm.Quantity"
                                name="T+"
                                placeholder="T+"
                                type="number"
                                
                              />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                  
                    <b-col cols="6" xl="1" lg="2" md="4" sm="6">
                      <b-form-group 
                      :label="$t('equity_bond.trade_form.amount')" 
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.amount')"
                        >
                   
                          <b-form-input
                          :disabled="true"
                                id="T+"
                                v-model.number="tradeForm.Amount"
                                name="T+"
                                placeholder="T+"
                                type="number"
                                
                              />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
              
                    <b-col cols="6" xl="1" lg="2" md="4" sm="6">
                      <b-form-group 
                      :label="$t('equity_bond.trade_form.tax')" 
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.tax')"
                          rules="required"

                        >
                    
                          <b-form-input
                                id="Tax"
                                v-model.number="tradeForm.TaxRate"
                                name="Tax"
                                placeholder="Tax"
                                type="number"
                                
                              />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    
                    <b-col cols="6" xl="1" lg="2" md="4" sm="6">
                      <b-form-group 
                      :label="$t('equity_bond.trade_form.tax')" 
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.tax')"
                          rules="required"

                        >
                    
                          <b-form-input
                            disabled
                                id="Tax"
                                v-model.number="tradeForm.Tax"
                                name="Tax"
                                placeholder="Tax"
                                type="number"
                                
                              />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                   
                    </b-row>
                  
                </b-card>
              </b-row>


            
            </b-form>
          </validation-observer>
          
        </b-container>
        
    </b-overlay>
    <template #modal-footer>
      <b-row class="align-items-center" style="flex: 1;">
          <!-- trade ok operation buttons -->
          <b-col cols="12" md="12" xl="12" lg="12" sm="12" class="d-flex justify-content-end align-items-center">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="secondary"
              size="sm"
              class="ml-1 modal-btn"
              @click="resetTradeModal"
            >
              {{ $t('equity_bond.modals.modal_cancel') }}
            </b-button>

            <b-button
            v-if="!tradeForm.VerifiedBy"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              size="sm"
              class="ml-1 modal-btn"
              @click="tradeOkOperation"
            >
              {{ $t('equity_bond.modals.verify_trade_modal_ok') }}
            </b-button>

            <b-button
            v-if="tradeForm.Reversed"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              size="sm"
              class="ml-1 modal-btn"
              @click="couponDuplicate"
            >
              Duplicate
            </b-button>
          </b-col>
        </b-row>
    </template>

    </b-modal>
  </div>



  
</template>

<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormRating,
  VBModal,
  BTabs,
  BTab,
  BCardText,
  BIcon,
  BPopover,
} from "bootstrap-vue";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import bondStoreModule from "./BondStoreModule";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, alphaNum, email } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import axios from "axios";
import axiosIns from "@/libs/axios";
import router from "@/router";
import moment from "moment";
import Cleave from "vue-cleave-component";
import "animate.css";
import _ from "lodash";
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BTabs,
    BTab,
    BCardText,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormRating,
    BIcon,
    BPopover,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
    flatPickr,
    Cleave,
  },

  directives: {
    Ripple,
    "b-modal": VBModal,
  },

  setup() {
    const EQUITYBONDS_APP_STORE_MODULE_NAME = "equity&bond";
    // Register module
    if (!store.hasModule(EQUITYBONDS_APP_STORE_MODULE_NAME))
      store.registerModule(
        EQUITYBONDS_APP_STORE_MODULE_NAME,
        bondStoreModule
      );

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(EQUITYBONDS_APP_STORE_MODULE_NAME))
        store.unregisterModule(EQUITYBONDS_APP_STORE_MODULE_NAME);
    });
  },

  mounted() {},
  props: {
      
 
    optCostCenters: {
      type: Array,
        default: function() {
        return [];
      },

    },
    optCounterParties: {
      type: Array,
        default: function() {
        return [];
      }
    },
  

    tradeForm: {
      type: Object,
      default: function() {
        return {
          Ticker: null,
          CostCenter: null,
          EntryDate: null,
          ValueDate: null,
          TcUser: null,
          TcUti: null,
          UniqueLinkId: null,
          Quantity: null,
          AccountName: null,
          AccountNumber: null,
          AccountCode: null,
          AccountType: null,
          Ccy: null,
          Amount: null,
          TaxRate: null,
          Tax: null,
          VerifiedBy: null,
          VerifyDate: null,
          counterpartyAccount:null,

        };
      }
}
   
},

  data() {
    return {
      allEquityBondsData: [],
      VerifyCouponModal: false,
      tradeModalLoading: false,
 
 
      weekday: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
      optSecurityDescriptions: [],
      optClientPriceTypes: [],
      optExchangeTrades: [],
      optCounterPartyTraders: [],
      optScfUsers: [],
      optActingCapacities: ["PRINCIPAL", "AGENT","MATCHED PRINCIPAL"],
      optOrderTakenVias: [],
      optOrderGivenThroughs: [],
      fetchedTradeData: {},
      actionsData: null,
      changedInputs: [],

      // tradeForm: {

      //   Ticker:null,
      //   CostCenter:null,
      //   EntryDate:null,
      //   ValueDate:null,
      //   TcUser:null,
      //   TcUti:null,
      //   UniqueLinkId:null,
      //   RelatedTradeUti:null,
      //   AccountName:null,
      //   AccountNumber:null,
      //   AccountCode:null,
      //   AccountType:null,
      //   Ccy:null,
      //   Amount:null,
      //   Tax:null,
      //   VerifiedBy:null,
      //   VerifyDate:null

     
      // },
   
      timeConfig: {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i:s",
        time_24hr: true,
      },
      dateConfig: {
        allowInput:true,
        dateFormat: "d-m-Y",
      },
      orderDateConfig: {
        allowInput:true,
        dateFormat: "d-m-Y",
        // onChange: function(selectedDates, dateStr, instance) {
        //   console.log(instance)
        // }
      },
      cleaveOptions: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
        },
        time: {
          time: true,
          timePattern: ["h", "m", "s"],
        },
      },
      cleave6DecimalOptions: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
          numeralDecimalScale: 6,
        },
      },
      cleaveQuantityOptions: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
          numeralDecimalScale: 0,
          numeralIntegerScale: 0,
        },
      },
    };
  },
  computed: {
    user: {
      get() {
        return this.$store.getters["authUser"];
      },
      set(newValue) {
        return this.$store.dispatch("setUser", newValue);
      },
    },
  },
  methods: {
    ...mapActions(["saveLastSearchBond"]),
    getArangeEquityBondTCUsers() {
      store
        .dispatch("equity&bond/fetchArangeEquityBondTCUsers", {
          auth: this.user,
          teamName: this.user.role,
        })
        .then((res) => {
          if (res.data.info == "Pass") {
            this.optScfUsers = res.data.users;
            this.getArrangeEquityBondOrderTakenGivenThrough();
          } else if (res.data.info == "no_users") {
            this.errorMessage(this.$t('equity_bond.messages.user_error_no_users'));
          } else {
            this.errorMessage(res.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getArrangeEquityBondOrderTakenGivenThrough() {
      store
        .dispatch(
          "equity&bond/fetchArrangeEquityBondOrderTakenGivenThrough",
          this.user
        )
        .then((res) => {
          if (
            res.data.orderGivenThroughs.length > 0 ||
            res.data.orderTakenVias.length > 0
          ) {
            this.optOrderGivenThroughs = res.data.orderGivenThroughs;
            this.optOrderTakenVias = res.data.orderTakenVias;
          } else {
            this.errorMessage(this.$t('equity_bond.messages.not_found_given_order_data'));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getArrangeEquityBondCurrencies() {
      store
        .dispatch("equity&bond/fetchArrangeEquityBondCurrencies", this.user)
        .then((res) => {
          if (res.data.info == "Pass") {
            this.optClientPriceTypes = res.data.currencies;
          } else {
            this.errorMessage(res.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    tradeOkOperation(bvModalEvt) {
      bvModalEvt.preventDefault();
   
      this.$refs.tradeValidation.validate().then((success) => {
        if (success) {
                  this.tradeModalLoading = true
                store
                  .dispatch("equity&bond/couponVerify", {
                    auth: this.user,
                    trade: this.tradeForm,
                  })
                  .then((res) => {
                    if (res.data.info == "Pass") {
                        this.verifiedTradeToastMessage()
                        this.resetTradeModal()
                        this.$emit('refresh');

                        this.tradeModalLoading = false
                    } else {
                  
                      this.checkTradeVerifyOperationMessage(res.data.message);
                      this.tradeModalLoading = false
                    }
                  })
                  .catch((error) => {
                    this.tradeModalLoading = false
                    console.log(error);
                  });
                }
              });

           

                  
           
   
    },
  
    resetTradeModal() {
      this.VerifyCouponModal = false;
      this.fetchedTradeData = {};
      this.actionsData = null;
      this.changedInputs = [];
    },
    databaseErrorMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: this.$t('equity_bond.toast_messages.database_connection_error'),
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },
    checkTradeVerifyOperationMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    checkTradeVerifyOperationLangMessage(user, date) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t("backend_language_message.verify_trade1") + ' ' + user + ' ' + this.$t("backend_language_message.verify_trade_on") + ' ' + date,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    anotherUserVerifyMessage() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t("backend_language_message.another_user_verify"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    checkRequiredInputsMessage(message, timeout) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Warning",
            text: this.$t('equity_bond.messages.required_fill_inputs') + ` ${message}`,
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        },
        {
          timeout,
        }
      );
    },
    notAuthToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Not Authorized",
          text: this.$t('equity_bond.general_messages.not_authorized_message'),
          icon: "UserXIcon",
          variant: "warning",
        },
      });
    },
    notFoundUTIValue(timeout) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Not Found UTI",
          text: this.$t('equity_bond.messages.not_found_uti'),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      },{
          timeout,
        });
    },
    errorMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    wrongToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: val,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    verifiedTradeToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successfully",
          text: this.$t('equity_bond.toast_messages.trade_verified_success'),
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
    },

    duplicatedTradeToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successfully",
          text: 'The coupon has been duplicated successfully.',
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    clearDate() {
      this.newHoliday.date = null;
    },
    DMYdateFormat(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY").format("DD-MM-YYYY");
      }
    },
    MDYdateFormat(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY").format("MM-DD-YYYY");
      }
    },
    YMDdateFormat(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY").format("YYYY-MM-DD");
      }
    },
    dateFormat(val) {
      if (val != undefined) {
        return moment(String(val)).format("DD-MM-YYYY");
      }
    },
    timeFormat(val) {
      if (val != undefined) {
        return moment(String(val), "HH:mm:ss")._i;
      }
    },
    HmstimeFormat(val) {
      if (val != undefined) {
        return moment(String(val)).format("HH:mm:ss");
      }
    },
    dateFormatWithTime(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY HH:mm:ss").format(
          "DD-MM-YYYY HH:mm:ss"
        );
      }
    },
    formatPrice(value, val) {
      if (value != "") {
        let val = (value / 1).toFixed(2).replace(",", ".");
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    couponDuplicate(){

      store
                  .dispatch("equity&bond/couponDuplicate", {
                    auth: this.user,
                    trade: this.tradeForm,
                  })
                  .then((res) => {
                    if (res.data.info == "Pass") {
                        this.duplicatedTradeToastMessage()
                        this.resetTradeModal()
                        this.tradeModalLoading = false
                    } else {
                  
                      this.checkTradeVerifyOperationMessage(res.data.message);
                      this.tradeModalLoading = false
                    }
                  })
                  .catch((error) => {
                    this.tradeModalLoading = false
                    console.log(error);
                  });


    },

  
  },
  watch: {
    "tradeForm.TaxRate": {
      handler: function(val, before) {
        if (val != null) {
          this.tradeForm.Tax = this.tradeForm.Amount * val;
     
        }
      },
      deep: true,
    },
  },
};
</script>

<style>
#tradeModal .modal-header .modal-title {
  color: white !important;
}

.eq_color .vs__dropdown-toggle .vs__selected-options .vs__selected {
  color: #191970;
}

.fi_color .vs__dropdown-toggle .vs__selected-options .vs__selected {
  color: #cd853f;
}

.settlementDateName .input-group-append .input-group-text {
  color: red;
}

.tradeDateName .input-group-append .input-group-text {
  color: red;
}

#actions-popover {
  width: 500px !important;
  max-width: 500px !important;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.change_selectbox_style {
  .vs__dropdown-toggle{
    .vs__selected-options{
      .vs__selected {
        color:orange
       }
    }
  }
}

</style>



<!-- compact design scss code -->

<style lang="scss" scoped>

@import "../../assets/scss/compact-design.scss";


// .v-select::v-deep .vs__selected {
//     position: relative !important;
//   }
</style>