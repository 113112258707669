<template>
  <div class=" mt-2">
    <b-card class="mb-0" no-body>
      <template #header>
        <div class=" d-flex justify-content-start" style="align-items: center;">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-secondary"
            size="sm"
            style="align-items:center;"
            @click="back()"
          >
            <feather-icon icon="ArrowLeftCircleIcon" class="mr-50" size="14" />
            <span class="align-middle">{{ $t("general_buttons.back") }}</span>
          </b-button>
          <h4 class="mb-0 ml-2">Coupon Payments</h4>
        </div>

        <div
          class=" d-flex justify-content-end"
          style="align-items: center;"
          v-if="!verifiedOnly"
        >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="success"
            size="sm"
            style="align-items:center;"
            @click="getVerifiedCoupons()"
          >
            <feather-icon icon="CheckCircleIcon" class="mr-50" size="14" />
            <span class="align-end"> Show Verified Entries</span>
          </b-button>
        </div>

        <div
          class=" d-flex justify-content-end"
          style="align-items: center;"
          v-else
        >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-secondary"
            size="sm"
            style="align-items:center;"
            @click="getAllBondCoupons()"
          >
            <feather-icon icon="CheckCircleIcon" class="mr-50" size="14" />
            <span class="align-end"> Show Pending Entries</span>
          </b-button>
        </div>
      </template>
      <b-overlay :show="loading" rounded="sm" no-fade>
        <hr />

        <b-table
          striped
          hover
          ref="refAccountCodesTable"
          class="position-relative compact_table"
          :items="allAccountCodes"
          :filter="filter"
          filter-debounce="250"
          @filtered="onFiltered"
          @row-clicked="onRowSelected"
          :filter-included-fields="filterOn"
          selectable
          select-mode="single"
          responsive
          fixed
          head-variant="light"
          :fields="!verifiedOnly ? tableColumns : tableColumnsVerified"
          show-empty
          :empty-text="
            $t('account_creation.messages.no_matching_account_found')
          "
          :current-page="currentPage"
          :per-page="perPage"
          caption-top
          :small="true"
          thead-class="animate__animated animate__fadeIn"
          style="max-height: 95vh !important"
        >
          <template #cell(ID)="data">
            <b
              ><span class="font-weight-bold">
                <strong class="text-primary">#{{ data.item.id }}</strong>
              </span></b
            >

            <div
              v-if="verifiedOnly"
              class="d-flex justify-content-center align-items-center compact_controls_button animate__animated animate__fadeIn"
            >
              <b-button
                :id="
                  'trade-controls-' + data.item ? data.item.id.toString() : ''
                "
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="flat-primary"
                size="sm"
                class="top-btns"
                @click="
                  showTradeControlsPopover(
                    'trade-controls-' + data.item ? data.item.id.toString() : ''
                  )
                "
              >
                <i class="fa-solid fa-bars"></i>
              </b-button>
              <b-popover
                :target="
                  'trade-controls-' + data.item ? data.item.id.toString() : ''
                "
                triggers="focus"
                variant="primary"
              >
                <template #title>
                  {{ $t("equity_bond.titles.trade_controls") }}
                </template>

                <b-button
                  v-if="$Can('view_journals_equity')"
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-secondary"
                  size="sm"
                  class="top-btns mt-1"
                  @click="viewJournalEntries(data.item)"
                >
                  <span class="trades-font">
                    <feather-icon
                      icon="EyeIcon"
                      style="margin-right: 0.3rem;"
                    />{{ $t("equity_bond.buttons.view_journal_entries") }}</span
                  >
                </b-button>

                <b-button
                  v-if="$Can('reverse_equity') && !data.item.Reversed"
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-dark"
                  size="sm"
                  class="top-btns mt-1"
                  @click="reverseEntry(data.item)"
                >
                  <span class="trades-font">
                    <feather-icon
                      icon="CornerUpLeftIcon"
                      style="margin-right: 0.3rem;"
                    />{{ $t("equity_bond.buttons.reverse_entry") }}</span
                  >
                </b-button>
              </b-popover>
            </div>
          </template>

          <!-- Column: CreationDate -->
          <template #cell(EntryDate)="data">
            <span>{{ dateFormat(data.value) }}</span>
          </template>
          <!-- Column: CreationTime -->
          <template #cell(VerifyDate)="data">
            <span>{{ dateFormat(data.value) }}</span>
          </template>

          <template #cell(Reversed)="data">
            <span>{{ data.value == 1 ? "Reversed" : " " }}</span>
          </template>
        </b-table>

        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted"
                >{{ $t("general_text.showing") }} {{ dataMeta.from }}
                {{ $t("general_text.to") }} {{ dataMeta.to }}
                {{ $t("general_text.of") }} {{ dataMeta.of }}
                {{ $t("general_text.entries") }}</span
              >
            </b-col>

            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>

        {{ /*View Selected Trade for trades modal*/ }}
        <VerifyCoupon
          :trade-form="selectedCoupon"
          :opt-cost-centers="optCostCenters"
          :opt-counter-parties="optCounterParties"
          ref="VerifyCoupon"
          @refresh="getAllBondCoupons"
        />
        <ReverseEntry ref="refReverseEntry" @reversedEntry="reversedEntry" />
      </b-overlay>
    </b-card>
    <ViewCouponEntries
      ref="refViewJournalEntries"
      @createdJournalEntry="createdJournalEntry"
    />
  </div>
</template>

<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormRating,
  VBModal,
  BTabs,
  BTab,
  BCardText,
  BIcon,
  BInputGroup,
  BInputGroupAppend,
  BPopover,
} from "bootstrap-vue";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import bondStoreModule from "./BondStoreModule";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, alphaNum, email, min, max } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import "cleave.js/dist/addons/cleave-phone.us";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import router from "@/router";
import moment from "moment";
import "animate.css";
import _ from "lodash";
import { mapState, mapGetters } from "vuex";
import VerifyCoupon from "./VerifyCoupon.vue";
import ViewCouponEntries from "./ViewCouponEntries.vue";
import ReverseEntry from "./ReverseEntry.vue";

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BTabs,
    BTab,
    BCardText,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormRating,
    BIcon,
    BPopover,
    BInputGroup,
    BInputGroupAppend,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
    flatPickr,
    VerifyCoupon,
    ViewCouponEntries,
    ReverseEntry,
  },

  directives: {
    Ripple,
    "b-modal": VBModal,
  },

  setup() {
    const BOND_APP_STORE_MODULE_NAME = "bond";
    // Register module
    if (!store.hasModule(BOND_APP_STORE_MODULE_NAME))
      store.registerModule(BOND_APP_STORE_MODULE_NAME, bondStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(BOND_APP_STORE_MODULE_NAME))
        store.unregisterModule(BOND_APP_STORE_MODULE_NAME);
    });
  },

  data() {
    return {
      verifiedOnly: false,
      allAccountCodes: [],
      optCostCenters: [],
      optCounterParties: [],
      defaultCenter: null,
      updatePermloading: false,
      AccountModalLoading: false,
      preAccount: null,
      accountData: {
        AccountName: null,
        AccountNumber: null,
        HolderType: "INDIVIDUAL",
        SettlementType: "CUSTODIAN",
        DisplayName: null,
        Phone1: null,
        Phone2: null,
        ContactEmail: null,
        Email2: null,
        Address: null,
        ID: null,
        AmendedBy: null,
        AmendmentDate: null,
        AmendmentReason: null,
        AmendmentTime: null,
        ClientNote: null,
        CreatedBy: null,
        CreationDate: moment().format("YYYY-MM-DD"),
        CreationTime: moment().format("HH:mm:ss"),
        VerificationDate: null,
        VerificationTime: null,
        VerifiedBy: null,
      },
      filterOn: ["AccountName"],
      tableColumns: [
        {
          key: "ID",
          label: "ID",
          class: "text-center w-0 table-padding",
        },
        {
          key: "AccountName",
          label: this.$t("account_creation.code_table.account_name"),
          sortable: true,
          class: "text-center table-padding",
        },
        {
          key: "AccountNumber",
          label: this.$t("account_creation.code_table.account_number"),
          sortable: false,
          class: "text-center table-padding",
        },

        {
          key: "AccountCode",
          label: this.$t("account_creation.code_table.account_code"),
          sortable: false,
          class: "text-center table-padding",
        },
        {
          key: "Ticker",
          label: "Ticker",
          sortable: false,
          class: "text-center table-padding",
        },
        {
          key: "Quantity",
          label: "Period Quantity",
          sortable: false,
          class: "text-center table-padding",
        },
        {
          key: "Amount",
          label: "Amount",
          sortable: false,
          class: "text-center table-padding",
        },
        {
          key: "Tax",
          label: "Tax",
          sortable: false,
          class: "text-center table-padding",
        },
        {
          key: "EntryDate",
          label: "Entry Date",
          sortable: true,
          class: "text-center table-padding",
        },
      ],
      tableColumnsVerified: [
        {
          key: "ID",
          label: "ID",
          class: "text-center w-0 table-padding",
        },
        {
          key: "AccountName",
          label: this.$t("account_creation.code_table.account_name"),
          sortable: true,
          class: "text-center table-padding",
        },
        {
          key: "AccountNumber",
          label: this.$t("account_creation.code_table.account_number"),
          sortable: false,
          class: "text-center table-padding",
        },

        {
          key: "AccountCode",
          label: this.$t("account_creation.code_table.account_code"),
          sortable: false,
          class: "text-center table-padding",
        },
        {
          key: "Ticker",
          label: "Ticker",
          sortable: false,
          class: "text-center table-padding",
        },
        {
          key: "Quantity",
          label: "Period Quantity",
          sortable: false,
          class: "text-center table-padding",
        },
        {
          key: "Amount",
          label: "Amount",
          sortable: false,
          class: "text-center table-padding",
        },
        {
          key: "Tax",
          label: "Tax",
          sortable: false,
          class: "text-center table-padding",
        },
        {
          key: "EntryDate",
          label: "Entry Date",
          sortable: true,
          class: "text-center table-padding",
        },
        {
          key: "VerifiedBy",
          label: "Verified By",
          sortable: false,
          class: "text-center table-padding",
        },
        {
          key: "Reversed",
          label: "Reversed",
          sortable: false,
          class: "text-center table-padding",
        },
      ],
      perPage: 10,
      currentPage: 1,
      totalRows: 0,
      perPageOptions: [10, 25, 50, 100],
      filter: null,

      loading: false,
      filterDateStart: null,
      filterDateEnd: null,
      selectedCoupon: {
        Ticker: null,
        CostCenter: null,
        EntryDate: null,
        ValueDate: null,
        TcUser: null,
        TcUti: null,
        UniqueLinkId: null,
        Quantity: null,
        AccountName: null,
        AccountNumber: null,
        AccountCode: null,
        AccountType: null,
        Ccy: null,
        Amount: null,
        Tax: null,
        VerifiedBy: null,
        VerifyDate: null,
        counterpartyAccount: null,
      },
    };
  },
  computed: {
    user: {
      get() {
        return this.$store.getters["authUser"];
      },
      set(newValue) {
        return this.$store.dispatch("setUser", newValue);
      },
    },
    ...mapGetters(["authUser"]),
    dataMeta() {
      return {
        from:
          this.totalRows != 0 ? this.perPage * (this.currentPage - 1) + 1 : 0,
        to:
          this.perPage * (this.currentPage - 1) + Number(this.perPage) <
          this.totalRows
            ? this.perPage * (this.currentPage - 1) + Number(this.perPage)
            : this.totalRows,
        of: this.totalRows,
      };
    },
  },
  mounted() {
    this.getAllBondCoupons();
  },
  methods: {
    back() {
      router.go(-1);
    },
    onRowSelected(row) {
      row.costCenter = this.defaultCenter;
      this.selectedCoupon = row;

      this.$refs.VerifyCoupon.VerifyCouponModal = true;
    },

    getAllBondCoupons() {
      this.loading = true;
      store
        .dispatch("bond/getAllBondCoupons", this.user)
        .then((res) => {
          this.allAccountCodes = res.data.coupons;
          this.optCostCenters = res.data.centers;
          this.defaultCenter = res.data.default_center.Value;
          this.verifiedOnly = false;
          this.optCounterParties = [];

          res.data.clients.forEach((data) => {
            this.optCounterParties.push(data);
          });

          this.totalRows = res.data.length;
          this.loading = false;
        })
        .catch((error) => {
          this.databaseErrorMessage();
          console.log(error);
          this.loading = false;
        });
    },

    getVerifiedCoupons() {
      this.loading = true;
      store
        .dispatch("bond/getVerifiedCoupons", this.user)
        .then((res) => {
          this.allAccountCodes = res.data.coupons;
          this.optCostCenters = res.data.centers;
          this.defaultCenter = res.data.default_center.Value;
          this.optCounterParties = [];
          res.data.clients.forEach((data) => {
            this.optCounterParties.push(data);
          });

          this.totalRows = res.data.length;
          this.verifiedOnly = true;
          this.loading = false;
        })
        .catch((error) => {
          this.databaseErrorMessage();
          console.log(error);
          this.loading = false;
        });
    },

    databaseErrorMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: this.$t("account_creation.messages.database_connection_error"),
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },
    errorToastMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },
    warningMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    errorMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },
    notAuthToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Not Authorized",
          text: this.$t("account_creation.messages.not_authorized"),
          icon: "UserXIcon",
          variant: "warning",
        },
      });
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    dateFormat(val) {
      if (val != undefined && val != null && val != "") {
        return moment(val).format("DD/MM/YYYY");
      } else {
        return "-";
      }
    },
    clockFormat(val) {
      if (val != undefined && val != null && val != "") {
        return moment(val).format("HH:mm:ss");
      } else {
        return "-";
      }
    },

    showTradeControlsPopover(targetTrade) {
      this.$root.$emit("bv::show::popover", targetTrade);
    },

    reverseEntry(trade) {
      this.$swal({
        title: this.$t("equity_bond.swal.reverse_trade"),
        text: this.$t("equity_bond.swal.if_reverse_entry_wont_acton_back"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: this.$t("equity_bond.swal.continue_button"),
        cancelButtonText: this.$t("equity_bond.swal.cancel_button"),
        customClass: {
          confirmButton: "btn btn-success mr-1",
          cancelButton: "btn btn-danger",
          denyButton: "btn btn-primary",
        },
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
      })
        .then((result) => {
          if (result.isConfirmed) {
            store
              .dispatch("equity&bond/reverseCouponEntry", {
                auth: this.user,
                tradeId: trade.id,
                tcUti: trade.TcUti,
                trade: trade,
              })
              .then((res) => {
                if (res.data.info == "Pass") {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Successfully",
                      text: this.$t(
                        "equity_bond.toast_messages.trade_reversed_success"
                      ),
                      icon: "ThumbsUpIcon",
                      variant: "success",
                    },
                  });
                  this.getVerifiedCoupons();
                }
              })
              .catch();
          }
        })
        .catch();
    },

    viewJournalEntries(item) {
      store
        .dispatch("equity&bond/getViewCouponJournalTrade", {
          auth: this.user,
          tradeId: item.id,
          tcUti: item.TcUti,
          trade: item,
        })
        .then((res) => {
          if (res.data.info == "Pass") {
            this.$refs.refViewJournalEntries.journalEntries = res.data.entries;

            this.$refs.refViewJournalEntries.createJournalOperation = false;

            //set timeline datas
            let actionsArray = [];
            if (
              res.data.trade.SavedBy != null &&
              res.data.trade.SavedBy != null
            ) {
              actionsArray.push({
                type: "Saved",
                user: res.data.trade.SavedBy ?? null,
                date:
                  res.data.trade.SaveDate.split(" ")[0] +
                  " " +
                  res.data.trade.SaveTime,
                reason: "-",
              });
            }

            if (
              res.data.trade.AmendedBy != null &&
              res.data.trade.AmendedBy != null
            ) {
              actionsArray.push({
                type: "Amended",
                user: res.data.trade.AmendedBy ?? null,
                date:
                  res.data.trade.AmendmentDate.split(" ")[0] +
                  " " +
                  res.data.trade.AmendmentTime,
                reason: res.data.trade.AmendmentReason,
              });
            }
            if (res.data.actions.length > 0) {
              if (
                res.data.actions[0].VerificationUser != null &&
                res.data.actions[0].VerificationUser != null
              ) {
                this.$refs.refViewJournalEntries.checkVerify = true;
                actionsArray.push({
                  type: "Verified",
                  user: res.data.actions[0].VerificationUser ?? null,
                  date: res.data.actions[0].VerificationDateTime,
                  reason: "-",
                });
              }
            }

            if (res.data.actions.length > 0) {
              if (
                res.data.actions[0].JournalUser != null &&
                res.data.actions[0].JournalUser != null
              ) {
                actionsArray.push({
                  type: "Journal Created",
                  user: res.data.actions[0].JournalUser ?? null,
                  date: res.data.actions[0].JournalDateTime,
                  reason: "-",
                });
              }
            }

            if (
              res.data.trade.CancelledBy != null &&
              res.data.trade.CancelledBy != null
            ) {
              actionsArray.push({
                type: "Canceled",
                user: res.data.trade.CancelledBy ?? null,
                date:
                  res.data.trade.CancelDate.split(" ")[0] +
                  " " +
                  res.data.trade.CancelTime,
                reason: res.data.trade.CancelReason,
              });
            }

            if (res.data.actions.length > 0) {
              if (
                res.data.actions[0].ReversalInitiatedBy != null &&
                res.data.actions[0].ReversalInitiatedBy != null
              ) {
                actionsArray.push({
                  type: "Reversal Initiated",
                  user: res.data.actions[0].ReversalInitiatedBy ?? null,
                  date: res.data.actions[0].ReversalInitiatedDateTime,
                  reason: res.data.actions[0].ReversalInitiatedReason,
                });
              }
            }

            if (res.data.actions.length > 0) {
              if (
                res.data.actions[0].ReversedBy != null &&
                res.data.actions[0].ReversedBy != null
              ) {
                actionsArray.push({
                  type: "Reversed",
                  user: res.data.actions[0].ReversedBy ?? null,
                  date: res.data.actions[0].ReversedDateTime,
                  reason: "-",
                });
              }
            }

            this.$refs.refViewJournalEntries.actionsData = actionsArray;
            this.$refs.refViewJournalEntries.viewJournalTradeModal = true;
          } else {
            this.notFoundMessage();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    createdJournalEntry() {},
    reversedEntry() {},
  },
  watch: {
    perPage: {
      handler: function(val, before) {
        localStorage.setItem("userShow", val);
      },
    },
  },
};
</script>

<style>
#accountModal .modal-header .modal-title {
  color: white !important;
}

.table-amended {
  background-color: #ffff99 !important;
}
</style>

<style scoped>
.padding_08 {
  padding: 0.7rem !important;
}
/* .table-verified {
  background-color: greenyellow !important;
} */

#table-container {
  display: flex;

  flex-wrap: nowrap;

  overflow-x: auto;
}

#table-container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.ID_background {
  background-color: gainsboro !important;
}
</style>

<style>
.compact-textarea-lg {
  min-height: 40px !important;
}
.column_search_input {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  min-width: 150px !important;
}

.ID_column_search_input {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
}

.sticky-column {
  position: sticky;
  background-color: #fff;
  z-index: 1;
}
.sticky-column:nth-child(1) {
  left: 0;
}
.sticky-column:nth-child(2) {
  left: 50px;
}
.other-col {
  z-index: 0;
}

.b-table-sticky-column:last-child {
  right: 0;
}

.sticky-header {
  position: sticky;
  top: 78px;
  z-index: 999;
  /* Add other styling as needed */
}

.b-table-sticky-header {
  max-height: unset !important;
  height: inherit !important;
}
.hide-rows thead tr[role="row"] {
  display: none;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(77, 73, 73);
}
</style>

<style lang="scss" scoped>
.top-btns {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "~vue-context/src/sass/vue-context";
</style>
<!-- compact design scss code -->

<style lang="scss" scoped>
@import "../../assets/scss/compact-design.scss";

// .v-select::v-deep .vs__selected {

//     position: relative !important;

//   }
</style>
